import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook2/image/imgPortadaEbook2.png"
import img1 from "@components/pageEbook/ebooks/ebook2/image/imgCard1.png"
import img2 from "@components/pageEbook/ebooks/ebook2/image/imgCard2.png"
import img3 from "@components/pageEbook/ebooks/ebook2/image/imgCard3.png"
import slider1 from "@components/pageEbook/ebooks/ebook2/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook2/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook2/image/imgSilder3.png"
import slider4 from "@components/pageEbook/ebooks/ebook2/image/imgSilder4.png"


const title = (
  <>
  <h1 className="titles-underlined-ebook ebook-ventas">
      Guía para una buena atención al cliente de un comercio electrónico
    </h1>
  </>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook">
      El consumo por internet aumenta y por ende las consultas dentro de un
      e-commerce. Recuerda que ofrecer una buena atención puede ser un factor
      diferencial respecto a la competencia.
      <br /> <br />
      Por eso, este e-book brinda información clara y sencilla sobre cómo crear
      una estrategia para atender mejor a los usuarios y cómo utilizar
      herramientas clave como los chatbots.
    </p>
  </div>
)
const textOne = (
  <p>
    La comunicación digital es muy cercana pese a no existir una interacción
    personal. Por eso, es importante saber comunicarnos bien con los clientes
    para garantizar una buena experiencia.
    <br /> <br />
    En medios digitales, la comunicación se puede dar desde hablar con un
    usuario por chat, hasta enviarle correos personalizados.
  </p>
)
const textTwo = (
  <p>
    Relacionarnos con los clientes no es una tarea fácil. La comunicación entre
    empresa y usuario debe ser tocado con pinzas porque una mala forma de
    expresarse puede generar conflictos.
    <br /> <br />
    De este modo, se necesita crear una estrategia de atención al cliente que
    sirva como una base sólida la cual cumpla con las expectativas de los
    clientes.
  </p>
)
const textThree = (
  <p>
    Los chatbots son grandes aliados para ofrecer una atención más personalizada
    y rápida. Tenerlos como parte de tu estrategia te traerán grandes
    beneficios.
  </p>
)

const data = {
  start: {
    support: "Ebook | Ecommerce",
    title: title,
    description: textDescription,
    classtext: "text-description-ebook-ecommerce",
    link: linkForm,
    href: "/ebook-2.pdf",
    brochure: "ebook-atencion-cliente",
    img: imgPortadaEbook,
  },

  description: {
    cards: [
      {
        title: "¿Qué es la comunicación digital?",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "Cómo hacer una estrategia para mejorar la atención al cliente",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "Importancia del chatbot para la atención al cliente",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Saber lo que realmente significa una comunicación digital en una empresa.",
      },
      {
        key: 2,
        text: "Todos los pasos que debes seguir para crear una estrategia de atención al cliente digital.",
      },
      {
        key: 3,
        text: "Reconocer la importancia del uso los chatbots durante la atención al cliente.",
      },
      {
        key: 4,
        text: "Implementar correctamente bots en todos tus canales de comunicación digital.",
      },
      {
        key: 5,
        text: "Crear estrategias para atender mejor a los usuarios.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
      {
        key: 4,
        img: slider4,
      },
    ],
  },
}

export default data
